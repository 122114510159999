import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Chat from './components/chat/Chat';
import LandingPage from './components/landing/LandingPage';
import SignIn from './components/auth/SignIn';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Dashboard from './components/dashboard/Dashboard';
import VendorSignupForm from './components/auth/Signup';
import ForgotPassword from './components/auth/ForgotPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<VendorSignupForm />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/c/:chatBotId" element={<Chat />} />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
