import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Drawer } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Lead } from '../../types';
import {
  collection,
  DocumentReference,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import LeadDetails from './LeadDetails';

interface LeadProps {
  vendorRef: DocumentReference;
}

const Leads: React.FC<LeadProps> = ({ vendorRef }) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const leadsRef = collection(db, 'leads');
        const q = query(leadsRef, where('vendorRefId', '==', vendorRef));
        const querySnapshot = await getDocs(q);
        const leadsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Lead, 'id'>),
        }));
        setLeads(leadsData);
        setFilteredLeads(leadsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setLoading(false);
      }
    };
    fetchLeads();
  }, [vendorRef]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    const filteredData = leads.filter(
      (lead) =>
        lead.name.toLowerCase().includes(value.toLowerCase()) ||
        lead.email.toLowerCase().includes(value.toLowerCase()) ||
        lead.location.toLowerCase().includes(value.toLowerCase()) ||
        lead.primaryProductCategory
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        lead.phone.includes(value)
    );
    setFilteredLeads(filteredData);
  };

  const showDrawer = (lead: Lead) => {
    setSelectedLead(lead);
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
    setSelectedLead(null);
  };

  const columns: ColumnsType<Lead> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: Lead, b: Lead) => a.name.localeCompare(b.name),
      render: (text: string) => <span className="text-blue-500">{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: Lead, b: Lead) => a.email.localeCompare(b.email),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Product Category',
      dataIndex: 'primaryProductCategory',
      key: 'primaryProductCategory',
      sorter: (a: Lead, b: Lead) =>
        a.primaryProductCategory.localeCompare(b.primaryProductCategory),
    },
    {
      title: 'Confidence',
      dataIndex: 'leadConversionConfidenceOutOfHundred',
      key: 'leadConversionConfidenceOutOfHundred',
      sorter: (a: Lead, b: Lead) =>
        a.leadConversionConfidenceOutOfHundred -
        b.leadConversionConfidenceOutOfHundred,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'New', value: 'new' },
        { text: 'Contacted', value: 'contacted' },
        { text: 'Closed', value: 'closed' },
      ],
      onFilter: (value: boolean | Object, record: Lead) =>
        record.status === value,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text: any, record: Lead) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              // Handle view details
              showDrawer(record);
            }}
          >
            View
          </Button>
          {/* <Button
            type="link"
            onClick={() => {
              // Handle adding to cart
            }}
          >
            Add to Cart
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Leads</h1>
      <div className="mb-4">
        <Input
          placeholder="Search Leads"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>
      <Table<Lead>
        columns={columns}
        dataSource={filteredLeads}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
      />
      {/* Drawer Component */}
      <Drawer
        title={`Lead Details - ${selectedLead?.name}`}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerVisible}
        width={640}
      >
        {selectedLead && <LeadDetails lead={selectedLead} />}
      </Drawer>
    </div>
  );
};

export default Leads;
