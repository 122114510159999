import React, { useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Spin } from 'antd';
import GoogleButton from 'react-google-button';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showForgotPasswordLink, setShowForgotPasswordLink] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user.emailVerified === false) {
        setError('Email is not verified. Please verify your email.');
        return;
      }
      const token = await userCredential.user.getIdToken();
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, userCredential.user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        localStorage.setItem('vendorId', userData.vendorId);
      } else {
        setError('Vendor ID not found for user.');
        return;
      }
      navigate(`/dashboard`);
    } catch (error) {
      setError('Invalid email or password. Please try again.');
      setShowForgotPasswordLink(true);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      await userCredential.user.getIdToken();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, userCredential.user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        const vendorsCollection = collection(db, 'vendors');
        const newVendor = await addDoc(vendorsCollection, {
          name: userCredential.user.displayName,
          email: userCredential.user.email,
          createdAt: serverTimestamp(),
        });
        await setDoc(userDocRef, {
          vendorRefId: doc(db, 'vendors', newVendor.id),
        });
      }
      navigate(`/dashboard`);
    } catch (error) {
      console.log('Error signing in:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <a href="/" className="text-2xl font-bold text-blue-600">
          VinTin
        </a>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md relative">
          {loading && (
            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
              <Spin tip="Loading lead details..." />
            </div>
          )}
          <h2 className="mb-6 text-2xl font-bold text-center">Sign In</h2>
          <form onSubmit={handleSignIn}>
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`w-full border ${error ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none focus:ring`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block font-medium mb-1">
                Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className={`w-full border ${error ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none focus:ring`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="mb-4 text-red-500">{error}</p>}
            {showForgotPasswordLink && (
              <div className="mb-4 text-center">
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white rounded py-2 font-semibold hover:bg-blue-700 transition duration-200"
            >
              Sign In
            </button>
          </form>
          <hr className="mt-2" />
          <div className="mt-4"></div>
          <div className="w-full">
            <GoogleButton
              style={{ width: '100%' }}
              type="dark"
              onClick={handleGoogleSignIn}
            />
          </div>
          <div className="p-4 box mt-3 text-center">
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-600 hover:underline">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
