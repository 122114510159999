import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { SendOutlined } from '@ant-design/icons';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, isLoading }) => {
  const [message, setMessage] = useState<string>('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
    }
  };

  return (
    <div className="flex self-end p-4 mb-8">
      <TextArea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onPressEnter={handleSend}
        disabled={isLoading}
        placeholder="Type your message..."
        autoSize={{ minRows: 1, maxRows: 4 }}
      />
      {isLoading ? (
        <Spin className="mx-2" />
      ) : (
        <Button
          className="mx-2"
          type="primary"
          icon={<SendOutlined />}
          disabled={!message.trim()}
          onClick={handleSend}
        >
          Send
        </Button>
      )}
    </div>
  );
};

export default ChatInput;
