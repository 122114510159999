import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { Pie, Column } from '@ant-design/charts';
import { db } from '../../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  DocumentReference,
} from 'firebase/firestore';
import { Chatbot } from './chatbot/Chatbots';
import { Lead } from '../../types';

interface OverviewProps {
  vendorRef: DocumentReference;
}

const Overview: React.FC<OverviewProps> = ({ vendorRef }) => {
  const [totalChatbots, setTotalChatbots] = useState<number>(0);
  const [chatbotsByIndustry, setChatbotsByIndustry] = useState<any[]>([]);
  const [totalLeads, setTotalLeads] = useState<number>(0);
  const [leadsByProduct, setLeadsByProduct] = useState<any[]>([]);
  const [leadsComparisonData, setLeadsComparisonData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch chatbots
      const chatbotsRef = collection(db, 'chatbots');
      const chatbotsQuery = query(
        chatbotsRef,
        where('vendorRefId', '==', vendorRef)
      );
      const chatbotsSnapshot = await getDocs(chatbotsQuery);
      const chatbotsData = chatbotsSnapshot.docs.map(
        (doc) => doc.data() as Chatbot
      );

      setTotalChatbots(chatbotsData.length);

      // Process chatbots by industry
      const industryCounts: { [key: string]: number } = {};
      chatbotsData.forEach((chatbot) => {
        const industry = chatbot.industry || 'Unknown';
        industryCounts[industry] = (industryCounts[industry] || 0) + 1;
      });

      const industryData = Object.keys(industryCounts).map((industry) => ({
        type: industry,
        value: industryCounts[industry],
      }));
      setChatbotsByIndustry(industryData);

      // Fetch leads
      const leadsRef = collection(db, 'leads');
      const leadsQuery = query(leadsRef, where('vendorRefId', '==', vendorRef));
      const leadsSnapshot = await getDocs(leadsQuery);
      const leadsData = leadsSnapshot.docs.map((doc) => doc.data() as Lead);

      setTotalLeads(leadsData.length);

      // Process leads by product
      const productCounts: { [key: string]: number } = {};
      leadsData.forEach((lead) => {
        const product = lead.primaryProductCategory || 'Unknown';
        productCounts[product] = (productCounts[product] || 0) + 1;
      });

      const productData = Object.keys(productCounts).map((product) => ({
        type: product,
        value: productCounts[product],
      }));
      setLeadsByProduct(productData);

      // Process leads comparison
      const totalLeadsCount = leadsData.length;
      const qualifiedLeadsCount = leadsData.filter(
        (lead) => lead.leadConversionConfidenceOutOfHundred > 70
      ).length;

      const comparisonData = [
        { type: 'Total Leads', value: totalLeadsCount },
        { type: 'Qualified Leads', value: qualifiedLeadsCount },
      ];
      setLeadsComparisonData(comparisonData);
    };

    fetchData();
  }, [vendorRef]);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Overview</h1>
      <Row gutter={[16, 16]}>
        {/* Total Chatbots Card */}
        <Col xs={24} md={8}>
          <Card title="Total Chatbots" bordered={false}>
            <div className="text-4xl font-semibold text-center">
              {totalChatbots}
            </div>
          </Card>
        </Col>

        {/* Total Leads Card */}
        <Col xs={24} md={8}>
          <Card title="Total Leads" bordered={false}>
            <div className="text-4xl font-semibold text-center">
              {totalLeads}
            </div>
          </Card>
        </Col>

        {/* Placeholder for another metric */}
        <Col xs={24} md={8}>
          <Card title="Qualified Leads" bordered={false}>
            <div className="text-4xl font-semibold text-center">
              {leadsComparisonData.find(
                (data) => data.type === 'Qualified Leads'
              )?.value || 0}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Chatbots by Industry Pie Chart */}
      <div className="mt-8">
        <Card title="Chatbots by Industry" bordered={false}>
          <Pie
            data={chatbotsByIndustry}
            angleField="value"
            colorField="type"
            radius={0.8}
          />
        </Card>
      </div>

      {/* Leads by Product Pie Chart */}
      <div className="mt-8">
        <Card title="Leads by Product" bordered={false}>
          <Pie
            data={leadsByProduct}
            angleField="value"
            colorField="type"
            label={{ text: 'type' }}
          />
        </Card>
      </div>

      {/* Leads Comparison Bar Chart */}
      <div className="mt-8">
        <Card title="Leads Comparison" bordered={false}>
          <Column
            data={leadsComparisonData}
            xField="type"
            yField="value"
            xAxis={{ label: { autoRotate: false } }}
            meta={{
              type: { alias: 'Lead Type' },
              value: { alias: 'Count' },
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Overview;
