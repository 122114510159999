import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

import React from 'react';

const SignOutButton: React.FC = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optionally navigate to login or landing page
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  return (
    <button
      onClick={handleLogout}
      className="px-4 py-2 bg-gray-100 text-red-600 hover:bg-gray-200"
    >
      Sign Out
    </button>
  );
};

export default SignOutButton;
