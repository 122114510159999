// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Message } from './types';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCjFQwF492i7m-NI3cFBWSU8BAVT3nZdTM',
  authDomain: 'moneymitra-b4e7a.firebaseapp.com',
  databaseURL:
    'https://moneymitra-b4e7a-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'moneymitra-b4e7a',
  storageBucket: 'moneymitra-b4e7a.appspot.com',
  messagingSenderId: '265864976827',
  appId: '1:265864976827:web:8e0bf73c00f39312e38c9e',
  measurementId: 'G-XEHK4RR97X',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
export const db = getFirestore(app);

// Initialize analytics and get a reference to the service
export const analytics = getAnalytics(app);

// Export functions
const functions = getFunctions(app);
export const gptChat = httpsCallable<Message[], string>(functions, 'gptChat');
