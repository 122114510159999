import React, { useState } from 'react';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Spin, Modal } from 'antd';
import { FirebaseError } from 'firebase/app';

const auth = getAuth();

interface FormData {
  name: string;
  phone: string;
  email: string;
  password: string;
}

const VendorSignupForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    phone: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validate = (): boolean => {
    const newErrors: Partial<FormData> = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required.';
    }
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone Number is required.';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid.';
    }
    if (!formData.password.trim()) {
      newErrors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        await updateProfile(userCredential.user, {
          displayName: formData.name,
        });

        await sendEmailVerification(userCredential.user);
        const vendorsCollection = collection(db, 'vendors');
        const newVendor = await addDoc(vendorsCollection, {
          ...formData,
          createdAt: serverTimestamp(),
          userId: userCredential.user.uid,
        });
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, userCredential.user.uid);
        await setDoc(userDocRef, {
          vendorRefId: doc(db, 'vendors', newVendor.id),
          phone: formData.phone,
        });
        Modal.success({
          title: 'Please Verify Your Email',
          content:
            'A verification email has been sent to your email address. Please verify your email before login.',
          onOk() {
            navigate('/signin');
          },
        });
        navigate('/signin');
      } catch (error) {
        const firebaseError = error as FirebaseError;
        if (firebaseError.code === 'auth/email-already-in-use') {
          setErrors({ email: 'Email is already registered. Please log in.' });
        } else {
          console.error('Error in signup vendor data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <a href="/" className="text-2xl font-bold text-blue-600">
          VinTin
        </a>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full p-8">
        {isLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
            <Spin tip="Signing up..." />
          </div>
        )}
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center">Signup</h2>
          <form onSubmit={handleSubmit} noValidate>
            {/* Name Field */}
            <div className="mb-4">
              <label htmlFor="name" className="block font-medium mb-1">
                Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className={`w-full border ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                } rounded px-3 py-2 focus:outline-none focus:ring`}
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            {/* Phone Field */}
            <div className="mb-4">
              <label htmlFor="phone" className="block font-medium mb-1">
                Phone<span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                className={`w-full border ${
                  errors.phone ? 'border-red-500' : 'border-gray-300'
                } rounded px-3 py-2 focus:outline-none focus:ring`}
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`w-full border ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                } rounded px-3 py-2 focus:outline-none focus:ring`}
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            {/* Password Field */}
            <div className="mb-4">
              <label htmlFor="password" className="block font-medium mb-1">
                Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className={`w-full border ${
                  errors.password ? 'border-red-500' : 'border-gray-300'
                } rounded px-3 py-2 focus:outline-none focus:ring`}
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
            </div>
            {/* Signup Button */}
            <button
              type="submit"
              id="signup-button"
              className="w-full bg-blue-600 text-white rounded py-2 font-semibold hover:bg-blue-700 transition duration-200"
            >
              Signup
            </button>
          </form>
          <div className="p-4 box mt-3 text-center w-full bg-white shadow-md">
            Already have an account?{' '}
            <Link to="/signin" className="text-blue-600 hover:underline">
              Log In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorSignupForm;
