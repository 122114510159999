import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  Timestamp,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import ChatbotCard from './ChatbotCard';
import CreateChatbotModal, { ChatbotCreationData } from './CreateChatbotModal';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

export interface Chatbot extends ChatbotCreationData {
  id: string;
  published: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}
interface ChatbotsProps {
  vendorRef: DocumentReference;
}

const Chatbots: React.FC<ChatbotsProps> = ({ vendorRef }) => {
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editingChatbot, setEditingChatbot] = useState<Chatbot | null>(null);

  useEffect(() => {
    const chatbotsRef = collection(db, 'chatbots');
    const q = query(chatbotsRef, where('vendorRefId', '==', vendorRef));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const bots: Chatbot[] = [];
      snapshot.forEach((doc) => {
        bots.push({ id: doc.id, ...doc.data() } as Chatbot);
      });
      setChatbots(bots);
    });

    return () => unsubscribe();
  }, [vendorRef]);

  const handleCreateOrEditChatbot = async (
    chatbotData: ChatbotCreationData
  ) => {
    try {
      if (editingChatbot) {
        await setDoc(
          doc(db, 'chatbots', editingChatbot.id),
          {
            ...chatbotData,
            vendorRefId: vendorRef,
            published: false,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      } else {
        await addDoc(collection(db, 'chatbots'), {
          ...chatbotData,
          vendorRefId: vendorRef,
          createdAt: serverTimestamp(),
          published: false,
        });
      }
      setIsModalOpen(false);
      setEditingChatbot(null);
    } catch (error) {
      console.error('Error creating chatbot:', error);
    }
  };

  const setModalParams = (chatbot: Chatbot | null, openModal: boolean) => {
    setEditingChatbot(chatbot);
    setIsModalOpen(openModal);
  };

  // TODO: introduce loading to show while chatbots are fetched
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Your Chatbots</h1>
        <button
          onClick={() => setModalParams(null, true)}
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          <PlusCircleIcon className="h-6 w-6 mr-2" />
          Create New Chatbot
        </button>
      </div>

      {/* Chatbot Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {chatbots.map((chatbot) => (
          <ChatbotCard
            key={chatbot.id}
            onEdit={() => setModalParams(chatbot, true)}
            chatbot={chatbot}
          />
        ))}
      </div>

      {/* Create Chatbot Modal */}
      {isModalOpen && (
        <CreateChatbotModal
          isOpen={isModalOpen}
          onRequestClose={() => setModalParams(null, false)}
          onCreateOrEdit={handleCreateOrEditChatbot}
          initialValues={editingChatbot}
        />
      )}
    </div>
  );
};

export default Chatbots;
