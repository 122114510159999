// src/components/Dashboard.tsx

import React, { useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import Overview from './Overview';
import Chatbots from './chatbot/Chatbots';
import Leads from '../leads/Leads';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../AuthContext';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { Spin } from 'antd';
import Settings from './settings/Settings';

const Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
  const [vendorRefId, setVendorRefId] = React.useState<DocumentReference>();
  const { currentUser } = useAuth();

  const fetchAndSetVendor = async (userId?: string) => {
    const userDocRef = doc(db, `users/${userId}`);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      setVendorRefId(userDocSnap.data().vendorRefId);
    }
  };

  useEffect(() => {
    const userId = currentUser?.uid;
    fetchAndSetVendor(userId);
  }, [currentUser]);

  const navigation = [
    { name: 'Overview', to: '/dashboard/overview' },
    { name: 'Chatbots', to: '/dashboard/chatbots' },
    { name: 'Leads', to: '/dashboard/leads' },
    { name: 'Settings', to: '/dashboard/settings' },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-white shadow-md transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-200 ease-in-out z-30 md:relative md:translate-x-0`}
      >
        <div className="flex items-center justify-between px-4 py-3 md:hidden">
          <button
            onClick={() => setSidebarOpen(false)}
            className="text-gray-600 focus:outline-none"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <nav className="mt-4">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.to}
              onClick={() => setSidebarOpen(false)}
              className={({ isActive }) =>
                `block px-4 py-2 ${
                  isActive
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`
              }
            >
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>

      {/* Content Area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Mobile Header */}
        <header className="flex items-center justify-between px-4 py-3 bg-white shadow-md md:hidden">
          <button
            onClick={() => setSidebarOpen(true)}
            className="text-gray-600 focus:outline-none"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
          <h1 className="text-lg font-semibold text-blue-600">VinTin</h1>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto">
          <div className="px-6 py-8">
            {!vendorRefId ? (
              <Spin />
            ) : (
              <Routes>
                <Route
                  path="overview"
                  element={<Overview vendorRef={vendorRefId} />}
                />
                <Route path="settings" element={<Settings />} />
                <Route
                  path="chatbots"
                  element={<Chatbots vendorRef={vendorRefId} />}
                />
                <Route
                  path="leads"
                  element={<Leads vendorRef={vendorRefId} />}
                />
                {/* Default Route */}
                <Route
                  path="*"
                  element={<Overview vendorRef={vendorRefId} />}
                />
              </Routes>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
