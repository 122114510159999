import React from 'react';
import Profile from './Profile';
import SignOutButton from '../../auth/SignOutButton';

const Settings: React.FC = () => {
  return (
    <>
      <div className="flex justify-between">
        <Profile />
        <div>
          <SignOutButton />
        </div>
      </div>
      <h2 className="mt-8 text-2xl font-semibold">Contact Us</h2>
      <p>
        For any help or changes, send an email to{' '}
        <strong>contact@vintin.ai</strong>
      </p>
    </>
  );
};

export default Settings;
