import React, { useEffect, useState } from 'react';
import { db, gptChat } from '../../firebaseConfig';
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { Message } from '../../types';
import { useParams } from 'react-router-dom';

let chatId: DocumentReference;

const ChatApp: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vendorReference, setVendorReference] = useState<DocumentReference>();
  const [prompt, setPrompt] = useState<string>('');
  const { chatBotId } = useParams();

  useEffect(() => {
    chatId = doc(collection(db, 'chats'));
    const fetchAndSetVendor = async () => {
      const chatbotDocRef = doc(db, `chatbots/${chatBotId}`);
      const chatbotDocSnap = await getDoc(chatbotDocRef);
      if (chatbotDocSnap.exists()) {
        const { vendorRefId, greetingMessage, systemPrompt } =
          chatbotDocSnap.data();
        setVendorReference(vendorRefId);
        setPrompt(systemPrompt);
        setMessages([{ role: 'assistant', content: greetingMessage }]);
        setIsLoading(false);
      }
    };
    fetchAndSetVendor();
  }, [chatBotId]);

  const handleSendMessage = async (input: string) => {
    setIsLoading(true);

    let newMessages: Message[] = [
      ...messages,
      { content: input, role: 'user' },
    ];
    setMessages(newMessages);
    await setDoc(chatId, {
      messages: newMessages,
      updatedAt: serverTimestamp(),
      leadGenerated: false,
      vendorRefId: vendorReference,
      chatbotRefId: doc(db, `chatbots/${chatBotId}`),
    });

    try {
      const response = await gptChat([
        { role: 'system', content: prompt },
        ...newMessages,
      ]);
      const aiMessage = response.data;

      newMessages = [...newMessages, { content: aiMessage, role: 'assistant' }];

      setMessages(newMessages);
      await setDoc(chatId, {
        messages: newMessages,
        updatedAt: serverTimestamp(),
        leadGenerated: false,
        vendorRefId: vendorReference,
        chatbotRefId: doc(db, `chatbots/${chatBotId}`),
      });
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen overflow-y-hidden">
      <div className="grid flex-col h-full m-4 bg-white">
        <ChatWindow messages={messages} />
        <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default ChatApp;
