import React, { useEffect, useRef } from 'react';
import { Message } from '../../types';
import ChatMessage from './ChatMessage';

interface ChatWindowProps {
  messages: Message[];
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to the latest message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="p-2 flex flex-col overflow-y-auto max-h-full">
      {messages.map((message, index) => (
        <ChatMessage key={index} message={message}></ChatMessage>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatWindow;
