// src/components/Dashboard/ChatbotCard.tsx

import React from 'react';
import { Chatbot } from './Chatbots';
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { Spin } from 'antd';

interface ChatbotCardProps {
  chatbot: Chatbot;
  onEdit: (chatbot: Chatbot) => void;
}

const ChatbotCard: React.FC<ChatbotCardProps> = ({ chatbot, onEdit }) => {
  const baseUrl = 'https://vintin.ai';

  const handleCopyUrl = () => {
    const url = `${baseUrl}/c/${chatbot.id}`;
    navigator.clipboard.writeText(url).then(
      () => {
        // TODO: use a notification framework instead of browser alert.
        alert('URL copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  const handleEdit = () => {
    onEdit(chatbot);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {chatbot.name} - {chatbot.industry}
        </h2>
        <p className="text-gray-600">
          <strong>Description</strong>: {chatbot.description || 'N/A'}
        </p>
        <p className="text-gray-600 mt-2">
          <strong>Greeting Message</strong>: {chatbot.greetingMessage || 'N/A'}
        </p>
        <p className="text-gray-600 mt-2">
          <strong>Instructions</strong>: Click Edit to see!
        </p>
      </div>
      <div className="flex justify-between mt-4 space-x-2">
        <button
          onClick={handleEdit}
          className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
        >
          <PencilSquareIcon className="h-6 w-6 mr-2" />
          Edit
        </button>
        <button
          onClick={handleCopyUrl}
          disabled={!chatbot.published}
          className="flex items-center bg-yellow-200 text-gray-900 px-4 py-2 rounded hover:bg-yellow-300 disabled:bg-gray-100"
        >
          {chatbot.published ? (
            <>
              <DocumentDuplicateIcon className="h-6 w-6 mr-2" />
              Copy URL
            </>
          ) : (
            <Spin />
          )}
        </button>
      </div>
    </div>
  );
};

export default ChatbotCard;
