import React, { useEffect, useState } from 'react';
import { Lead, Message } from '../../types';
import { getDoc } from 'firebase/firestore';
import { Spin, Timeline } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import remarkGfm from 'remark-gfm';

interface LeadDetailsProps {
  lead: Lead;
}

const LeadDetails: React.FC<LeadDetailsProps> = ({ lead }) => {
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const chatSnap = await getDoc(lead.chatRefId);
        if (chatSnap.exists()) {
          const chatData = chatSnap.data();
          setChatMessages(chatData.messages); // Assuming the chat messages are stored under 'messages'
        } else {
          console.error('No such chat document!');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat:', error);
        setLoading(false);
      }
    };

    fetchChat();
  }, [lead.chatRefId]);

  if (loading) {
    return <Spin tip="Loading lead details..." />;
  }

  return (
    <div>
      <p className="mt-1">
        <strong>Name:</strong> {lead.name}
      </p>
      <p className="mt-1">
        <strong>Email:</strong> {lead.email}
      </p>
      <p className="mt-1">
        <strong>Phone:</strong> {lead.phone}
      </p>
      <p className="mt-1">
        <strong>Location:</strong> {lead.location}
      </p>
      <p className="mt-1">
        <strong>Age:</strong> {lead.age}
      </p>
      <p className="mt-1">
        <strong>Preferred Time Slot:</strong> {lead.preferredTimeSlot}
      </p>
      <p className="mt-1">
        <strong>Status:</strong> {lead.status}
      </p>
      <p className="mt-1">
        <strong>Primary Product:</strong> {lead.primaryProduct}
      </p>
      <p className="mt-1">
        <strong>Primary Product Category:</strong> {lead.primaryProductCategory}
      </p>
      <p className="mt-1">
        <strong>Other Opportunities:</strong>{' '}
        {lead.otherOpportunities.join(' | ')}
      </p>
      <p className="mt-1">
        <strong>Relevant Keywords:</strong> {lead.relevantKeywords.join(' | ')}
      </p>
      <p className="mt-1">
        <strong>Summary:</strong> {lead.summary}
      </p>
      <p className="mt-1">
        <strong>Other Notes:</strong> {lead.anyOtherNotes}
      </p>
      <p className="mt-1">
        <strong>Confidence:</strong> {lead.leadConversionConfidenceOutOfHundred}
      </p>
      {/* Add more lead fields as necessary */}

      <h2 className="text-xl font-semibold mt-6 mb-4">Chat Transcript</h2>
      <div className="max-h-96 overflow-y-auto">
        <Timeline>
          {chatMessages.map((message, index) => (
            <Timeline.Item
              key={index}
              color={message.role === 'assistant' ? 'blue' : 'green'}
            >
              <p>
                <strong>
                  {message.role === 'assistant' ? 'MoneyMitra' : 'User'}:
                </strong>
              </p>
              <ReactMarkdown
                children={message.content}
                remarkPlugins={[remarkMath, remarkGfm]}
                rehypePlugins={[rehypeKatex]}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default LeadDetails;
